import React, { Fragment, useEffect } from 'react';
import { Modal, Form, Table, Typography, Button,Divider,Tooltip } from 'antd';
import { useRequest, useBoolean } from 'ahooks';

const { Column } = Table;
//const Hans = ['', '一', '二', '三', '四', '五', '六'];

export default function UserModal({ id,isLink }) {
  const [form] = Form.useForm();
  const [visible, { toggle }] = useBoolean();
  const { data, loading, run } = useRequest(
    {
      url: `/api/report/preview`,
      params: { id },
    },
    { manual: true }
  );
  useEffect(() => {
    if (visible) {
      run();
    }
  }, [visible, run]);

  return (
    <Fragment>
      {isLink?<>
      <Divider type="vertical" />
      <a onClick={() => toggle()}>
        统计
      </a>
      </>:<Button onClick={() => toggle()} style={{ marginLeft: 12 }}>
        统计
      </Button>}
      <Modal
        width={720}
        footer={null}
        title="订单统计"
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => toggle()}
      >
        <Table
          bordered
          rowKey="grade"
          size="small"
          loading={loading}
          pagination={false}
          dataSource={data?.data}
          style={{ marginTop: 12 }}
        >
          <Column
            title={
              <Fragment>
              <span>班级</span>
              <Tooltip title="打包下载全部" placement="bottom" >
                <Typography.Link
                  target="_blank"
                  style={{marginLeft:'20px'}}
                  href={`https://db.zhulj.cn/api/Report/Package?id=${id}`}
                >
                  下载全部
                </Typography.Link>
              </Tooltip>
            </Fragment>
            }
            key="name"
            dataIndex="name"
          />
          <Column
            align="center"
            title="半年"
            key="onlineHalf"
            dataIndex="onlineHalf"
          />
          <Column
            align="center"
            title="全年"
            key="onlineFull"
            dataIndex="onlineFull"
          /><Column
            title="按年级下载"
            key="download"
            render={(row) => (
              <Tooltip title={`下载${row.name}名单`} placement="rightTop" >
                <Typography.Link
                  target="_blank"
                  href={`https://db.zhulj.cn/api/Report/Package?id=${row.school}&grade=${row.grade}`}
                >
                  下载
                </Typography.Link>
              </Tooltip>
            )}
          />
        </Table>
      </Modal>
    </Fragment>
  );
}
