import React, { useState, Fragment } from 'react';
import {
  Table,
  Card,
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Form,
  Select,
  Input,
  Tag,
  message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { useStore } from 'hooks';
import axios from 'axios';
import dayjs from 'dayjs';

import DetailDrawer from './Detail';
import ReportModal from './Report';
import OfficeModal from './Office';
import WarehouseModal from './Warehouse';
import ExpressModal from './ExpressModal';
import Renewals from './Renewals';
import EditDrawer from './EditDrawer';

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

const STATUS = [
  '待申请',
  '已申请',
  '办公室已审核',
  '办公室打回',
  '仓库已审核',
  '仓库已打回',
  '已处理',
];

const STATUSCOLORS = [
  'default',
  'processing',
  'success',
  'error',
  'success',
  'error',
  'warning',
];

export default function List() {
  const { user } = useStore();
  const [status, setStatus] = useState('');
  const [year, setYear] = useState('');
  const [term, setTerm] = useState('');
  const [keywords, setKeywords] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const { data, loading, pagination, refresh } = useRequest(
    ({ current, pageSize }) => ({
      url: '/api/report/list',
      params: { page: page, size: size, keywords, year, term, status },
    }),
    {
      paginated: true,
      defaultPageSize: 15,
      refreshDeps:[page,size]
    }
  );
  const onMerge = (id) => {
    axios
      .post('/api/Report/Merge', {}, { params: { id } })
      .then(({ code, msg }) => {
        refresh();
        message[code ? 'error' : 'success'](msg);
      });
  };

  return (
    <Card bordered={false}>
      <Row justify="space-between" style={{ marginBottom: 12 }}>
        <Col>
          <h2>
            {user?.roles?.includes('storekeeper') ? '仓库物流' : '上报管理'}
          </h2>
        </Col>
        <Col>
          <Form layout="inline">
            <Form.Item>
              <Select
                placeholder="请选择学年"
                style={{ width: 140 }}
                onChange={(value) => setYear(value)}
              >

                <Option value={2021}>2020-2021年</Option>
                <Option value={2022}>2021-2022年</Option>
                <Option value={2023}>2022-2023年</Option>
                <Option value={2024}>2023-2024年</Option>
                <Option value={2025}>2024-2025年</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Select
                placeholder="请选择学期"
                style={{ width: 120 }}
                onChange={(value) => setTerm(value)}
              >
                <Option value={1}>上学期</Option>
                <Option value={2}>下学期</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Select
                style={{ width: 140 }}
                placeholder="请选择上报状态"
                onChange={(value) => setStatus(value)}
              >
                <Option value="">全部</Option>
                {STATUS.map((value, index) => (
                  <Option key={index} value={index}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Input
                placeholder="学校/发行员关键字"
                onChange={(e) => setKeywords(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button onClick={() => refresh()}>
                <SearchOutlined />
                查询
              </Button>
            </Form.Item>
            {/* {user?.roles?.includes('storekeeper') ? null : (
              <Link to="/report/input">
                <Button type="primary">录入</Button>
              </Link>
            )} */}
          </Form>
        </Col>
      </Row>
      <Table
        rowKey="id"
        bordered
        dataSource={data?.data?.items}
        loading={loading}
        pagination={{
          ...pagination,
          showSizeChanger: false,
          current:page,
          pageSize:size,
          total: data?.data?.count,
          onChange:(page,size)=>{
            setPage(page);
            setSize(size);
          },
          showTotal: (total) => `共 ${total} 条数据`,
        }}
      >
        <Column
          title="编号"
          key="no"
          render={(value, record, index) => index + 1}
        />
        <Column title="学校" dataIndex="schoolName" key="schoolName" />
        <Column
          title="学年"
          key="year"
          dataIndex="year"
          render={(year) => `${year - 1}-${year}年`}
        />
        <Column
          title="学期"
          key="term"
          dataIndex="term"
          render={(term) => (term === 1 ? '上学期' : '下学期')}
        />
        <Column title="发行员" key="ownerName" dataIndex="ownerName" />
        <Column
          title="状态"
          key="status"
          render={({ status, isRenew }) => (
            <Tag color={STATUSCOLORS[status]}>
              {isRenew ? '续订' : ''}
              {STATUS[status]}
            </Tag>
          )}
        />
        <Column
          title="操作时间"
          key="updateTime"
          dataIndex="updateTime"
          render={(date) => dayjs(date).format('YYYY-MM-DD HH:mm')}
        />
        <Column
          title="订报值"
          key="dbz"
          render={(row) => (
            <Fragment>
              <DetailDrawer {...row} />
              <Divider type="vertical" />
              {row.status > 0 && row.status !== 6 ? (
                <Typography.Link
                  target="_blank"
                  href={`https://db.zhulj.cn/api/Statistics/Download?id=${row.id}`}
                >
                  导出
                </Typography.Link>
              ) : (
                <Text disabled>导出</Text>
              )}
            </Fragment>
          )}
        />
        <Column
          title="操作"
          key="action"
          render={(row) => (
            <Fragment>
              {user?.roles?.includes('storekeeper') ? null : (
                <>
                  {row.status === 0 || row.status === 3 || row.status === 5 ? (
                    <EditDrawer {...row} onClose={() => refresh()} />
                  ) : (
                    <Text disabled>编辑</Text>
                  )}
                  <Divider type="vertical" />
                  {row.status === 0 || row.status === 3 || row.status === 5 ? (
                    <ReportModal data={row} onClose={() => refresh()} />
                  ) : (
                    <Text disabled>申请</Text>
                  )}
                  <Divider type="vertical" />
                  {row.status === 4 ? (
                    <Renewals {...row} onClose={() => refresh()} />
                  ) : (
                    <Text disabled>续订</Text>
                  )}
                  <Divider type="vertical" />
                  {row.status === 1 ? (
                    <OfficeModal data={row} onClose={() => refresh()} />
                  ) : (
                    <Text disabled>审核</Text>
                  )}
                </>
              )}
              {user?.roles?.includes('storekeeper') && (
                <>
                  <Divider type="vertical" />
                  {row.status === 2 ? (
                    <WarehouseModal data={row} onClose={() => refresh()} />
                  ) : (
                    <Text disabled>审核</Text>
                  )}
                  <Divider type="vertical" />
                  {row.isRenew && row.status === 4 ? (
                    <Typography.Link onClick={() => onMerge(row.id)}>
                      合并
                    </Typography.Link>
                  ) : (
                    <Text disabled>合并</Text>
                  )}
                  <Divider type="vertical" />
                  {row.status > 0 && row.status !== 6 ? (
                    <ExpressModal id={row.id} />
                  ) : (
                    <Text disabled>上报物流</Text>
                  )}
                </>
              )}
            </Fragment>
          )}
        />
      </Table>
    </Card>
  );
}
