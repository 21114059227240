import React, { useState } from 'react';
import { useStore } from 'hooks';
import {
  Divider,
  Table,
  Card,
  Row,
  Col,
  Input,
  Modal,
  Image,
  message,
  Tag,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import axios from 'axios';

import Area from 'components/Area';
import SchoolModal from './Modal';
import HalfModal from './Half';
import ReportStatus from './Status';
import ReportInput from '../Report/Input';
const { Column } = Table;
const { Search } = Input;

export default function List() {
  const { user } = useStore();
  const [name, setName] = useState('');
  const [area, setArea] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const { data, loading, pagination, refresh } = useRequest(
    ({ current, pageSize }) =>
      axios.get('/api/School/Table', {
        params: {
          page: page,
          size: size,
          name,
          pid: area[0] || '',
          cid: area[1] || '',
          did: area[2] || '',
        },
      }),
    {
      paginated: true,
      refreshDeps: [area,page,size],
      defaultPageSize: 15,
    }
  );

  const onDelete = ({ id, enable, name }) => {
    Modal.confirm({
      title: '操作提示',
      icon: <ExclamationCircleOutlined />,
      content: `${enable ? '停用' : '启用'}${name}?`,
      okType: 'danger',
      onOk: () => {
        axios.delete(`/api/school/${id}`).then(({ code, msg }) => {
          refresh();
          message[code ? 'error' : 'success'](msg);
        });
      },
    });
  };

  return (
    <Card bordered={false}>
      <Row justify="space-between" style={{ marginBottom: 8 }}>
        <Col>
          <h2>学校列表</h2>
        </Col>
        <Col>
          <Row gutter={16}>
            <Col>
              <Area
                changeOnSelect={true}
                onChange={(value) => setArea(value)}
                style={{ width: 220, marginRight: 20 }}
              />
              <Search
                // enterButton
                placeholder="输入学校名称"
                onChange={(e) => setName(e.target.value)}
                onSearch={() => refresh()}
                style={{ width: 240 }}
              />
            </Col>
            <Col>
              <SchoolModal onSuccess={() => refresh()} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        rowKey="id"
        bordered
        dataSource={data?.data?.items}
        loading={loading}
        pagination={{
          ...pagination,
          showSizeChanger: false,
          current:page,
          pageSize:size,
          total: data?.data?.count,
          onChange:(page,size)=>{
            setPage(page);
            setSize(size);
          },
          showTotal: (total) => `共 ${total} 条数据`,
        }}
      >
        <Column title="学校名称" dataIndex="name" key="name" />
        <Column title="驻地姓名" dataIndex="recipients" key="recipients" />
        <Column title="市" dataIndex="city" key="city" />
        <Column title="区" dataIndex="district" key="district" />
        <Column
          align="center"
          title="订阅年限"
          key="half"
          dataIndex="half"
          render={(half) => (half === 1 ? '半年' : '全年')}
        />
        <Column
          align="center"
          title="当前学期"
          key="currentTerm"
          dataIndex="currentTerm"
          render={(currentTerm) => (currentTerm === 2 ? '下学期' : '上学期')}
        />
        <Column
          align="center"
          title="状态"
          key="enable"
          dataIndex="enable"
          render={(enable) =>
            enable ? (
              <Tag color="#87d068">正常</Tag>
            ) : (
              <Tag color="#f50">停用</Tag>
            )
          }
        />
        <Column
          align="center"
          title="二维码"
          key="action"
          render={({ id }) => (
            <Image
              width={32}
              src={`https://db.zhulj.cn/api/Weixin/qrcode?school=${id}`}
            />
          )}
        />
        <Column
          title="操作"
          key="action"
          render={(row) => (
            <>
              <HalfModal {...row} onSuccess={() => refresh()} />
              <Divider type="vertical" />
              <SchoolModal {...row} onSuccess={() => refresh()} />
              <Divider type="vertical" />
              {row.enable ? (
                <a onClick={() => onDelete(row)}>停用</a>
              ) :
                <a onClick={() => onDelete(row)}>启用</a>
              }

              <ReportStatus id={row.id} type="online" />
              {user?.roles?.includes('storekeeper') ? null : (
                <>
                  <Divider type="vertical" />
                  <ReportInput schoolId={row.id} schoolName={row.name} schoolMax={row.max} />
                </>
              )}

            </>
          )}
        />
      </Table>
    </Card>
  );
}
